KPlusS = Object.assign({}, KPlusS || {}, (function () {
  let pendingSoldToPartyCode = ''
  let pendingShipToPartyCode = ''
  let $shipToUpdateModal = null

  return {
    shipTo: {
      selection: {
        modal: {
          toggleShipToSelectionModal,
        },
      },
      update: {
        modal: {
          openShipToUpdateModal,
          closeShipToUpdateModal
        },
        updateShipTo
      }
    }
  }

  //region - shipTo
  //region -- selection
  //region --- modal
  function toggleShipToSelectionModal() {
    $('#shipToSelectionModal').modal('toggle')
  }

  //endregion
  //endregion

  //region -- update
  function updateShipTo() {
    $.ajax({
      type: 'POST',
      url: $("div[data-update-ship-to-url]").data('updateShipToUrl'),
      data: {
        shipToParty: pendingShipToPartyCode || pendingSoldToPartyCode,
        soldToParty: pendingSoldToPartyCode || pendingShipToPartyCode
      },
      complete: () => {
        location.reload()
      }
    })
  }

  //region --- modal
  function openShipToUpdateModal() {
    const $clickedCard = $(this)
    const $shipToSelectionModal = $('#shipToSelectionModal')
    $shipToUpdateModal = $('#shipToUpdateModal')

    pendingSoldToPartyCode = $clickedCard.data('soldToPartyCode')
    pendingShipToPartyCode = $clickedCard.data('shipToPartyCode')

    $shipToSelectionModal.modal('hide')
    $shipToUpdateModal.modal('show')
  }

  function closeShipToUpdateModal() {
    $('#shipToUpdateModal').modal('hide')
    $('#shipToSelectionModal').modal('show')
  }

  //endregion
  //endregion
  //endregion
})())
