var Generic = Object.assign({}, Generic || {},
  (function () {
    "use strict";
    return {
      events: {
        abort: abortEvent,
        abortIfKeyIsEnter: abortIfKeyIsEnter
      }
    };

    function abortEvent(e) {
      e.preventDefault();
      e.stopPropagation();
      e.stopImmediatePropagation();
      return false;
    }

    function abortIfKeyIsEnter(e) {
      if (e.which === 13 || e.keyCode === 13 || e.key === 13) {
        return abortEvent(e);
      }
    }
  })()
);
