Generic = Object.assign({
  ajax: (function () {
    "use strict";
    var
      $body,
      $loadOnScroll = $([])
    ;

    $(function () {
      $body = $('body');
      $("meta[name='_csrf'][content]").each(initAjaxCsrfToken);

      /**
       * set window.location by special attribute in any DOM-Element
       */
      $body.on('click', '[data-location-url]', dataLocationUrl);

      /**
       * Loads data from Server asynchronous on click
       * on any element with the attributes data-reload-url and
       * either data-reload-target-selector
       * or data-reload-response-handler
       *
       * [data-reload-url]                        url; providing async-data
       * [data-reload-once]                       boolean do this only one time
       * [data-reload-on-scroll]                  trigger reload when viewport is scrolled until target becomes visible
       * [data-reload-target-selector]            css-selector; where to put the response-content (in case of html)
       * [data-reload-target-replace]             boolean; is the target to be replaced by the response?
       * [data-reload-response-handler]           callback-function; to pass the response to (eg. "window.foo.bar")
       * [data-reload-wait-class]                 css-classname; to set while waiting for response
       * [data-reload-wait-lock]                  boolean; block succeeding clicks on the same element while waiting?
       * [data-reload-on-http-error-notification] Text; fallback-Message to show as Generic.notification (type: error)
       * [data-reload-on-http-404-redirect-url]   url; to the 404-page
       * [data-reload-on-http-404-notification]   Text; to show as Generic.notification (type: error)
       * [data-reload-on-http-500-redirect-url]   url; to the 500-page
       * [data-reload-on-http-500-notification]   Text; to show as Generic.notification (type: error)
       *
       * [data-history-url]                       url; to replace the current browser-history element with
       */
      $body
        .on('click', '[data-reload-url][data-reload-target-selector],[data-reload-url][data-reload-response-handler]', reloadUrl)
        .on("ajaxreload", attachReloadTimers)
        .on("ajaxreload", initReloadOnScroll)
        .on("ajaxreload", ensureScripts)
      ;
      ensureScripts();
      attachReloadTimers();
      initReloadOnScroll();
      $(window).on("scrollingStep resizingStep", handleReloadOnScrollVisible);
      handleReloadOnScrollVisible();

      /**
       * Handle forms with target="ajax"
       * @namespace handleFormTargetAjax
       * - [data-ajax-url]
       * - [data-ajax-cache]
       * - [data-ajax-once]
       * - [data-ajax-wait-class]
       * - [data-ajax-wait-class-for]
       * - [data-ajax-wait-lock]
       * - [data-ajax-on-submit-add-original-form-data]   boolean; default = true => if set to false does not include formData from the form that triggered the request.
       * - [data-ajax-on-submit-merge-forms]              list css-selectors; split by comma => Adds value given forms in formData.
       * - [data-ajax-on-submit-merge-inputs]             list css-selectors; split by comma => Adds value of given inputs fields in formData.
       * - [data-ajax-on-submit-merge-url-params]         boolean; merges urls params in the formData.
       * - [data-ajax-on-submit-merge-function]           function; allows to add misc new formData attributes; must return array of obj {name, value}.
       * - [data-ajax-on-xhr-success]
       * - [data-ajax-on-xhr-success-target]
       * - [data-ajax-on-xhr-success-target-replace]
       * - [data-ajax-on-xhr-success-notification]
       * - [data-ajax-on-xhr-error]
       * - [data-ajax-on-xhr-complete]
       * - [data-ajax-on-http-500-notification]
       * - [data-ajax-on-http-404-notification]
       * - [data-ajax-on-http-error-notification]
       * - [data-ajax-on-http-401-redirect-url]
       */
      $body.on("submit", "form[data-ajax-url]", runFormAsync);
    });

    return {
      handleError: handleError,
      AbortMessage: abortMessage,
      attachReloadTimer: attachReloadTimer,
      runFormAsync: runFormAsync
    };

    function dataLocationUrl(e) {
      var url = $(this).data('location-url');
      if (url) {
        if (e.ctrlKey) {
          try {
            window.open(url, '_blank').focus();
          } catch (e) {
            window.location.href = url;
          }
        } else {
          window.location.href = url;
        }
      }
    }

    function initAjaxCsrfToken() {
      var token = $(this).attr('content');
      $(document).ajaxSend(function (e, xhr) {
        xhr.setRequestHeader('CSRFToken', token);
      });
      $.ajaxPrefilter(function (options) {
        var noData = typeof options.data === "undefined";
        if (noData || options.data.indexOf("CSRFToken") === -1) {
          options.data = (!noData ? options.data + "&" : "") + "CSRFToken=" + token;
        }
      });
    }

    function ensureScripts() {
      $("[data-ensure-script]:not(.ensure-script-done)")
        .addClass("ensure-script-done")
        .each(ensureScript);
    }

    function ensureScript() {
      var
        $this = $(this),
        url = $this.data("ensure-script"),
        loaded = $('script').filter(function () {
          return ($(this).attr('src') === url);
        }).length > 0
      ;
      if (!loaded) {
        $.getScript(url);
      }
    }

    function initReloadOnScroll() {
      $loadOnScroll.add("[data-reload-url][data-reload-on-scroll][data-reload-target-selector]:not(.data-reload-url--running,.data-reload-url--done)");
    }

    function handleReloadOnScrollVisible() {
      $loadOnScroll
        .filter(":not(.data-reload-url--running,.data-reload-url--done)").each(function () {
        var
          $this = $(this),
          $target = $($this.data("reload-target-selector")).filter(":visible")
        ;
        if ($target.length &&
          /^true$/i.test($this.data("reload-on-scroll")) &&
          checkScrolledIntoView.call($target[0])
        ) {
          $this.data("reload-on-scroll", "done");
          reloadUrl.call($this[0]);
        }
      });
    }

    function checkScrolledIntoView() {
      var
        $element = $(this),
        $container = getScrollContainer($element),
        top = getElementTopAbsolute($element, $container),
        bottom = getScrollContainerBottomAbsolute.call($container[0])
      ;
      return top < bottom;
    }

    function getScrollContainer($element) {
      var $container = $element.parents("[data-scroll-handler-container]").eq(0);
      return $container.length ? $container : $(window);
    }

    function getElementTopAbsolute($element) {
      return $element.offset().top;
    }

    function getScrollContainerBottomAbsolute() {
      var $container;
      if (this === document || this === window) {
        return $(document).scrollTop() + $(window).innerHeight();
      } else {
        $container = $(this);
        return $container.offset().top + $container.height();
      }
    }

    function attachReloadTimers() {
      $("[data-reload-url][data-reload-target-selector][data-reload-interval]," +
        "[data-reload-url][data-reload-response-handler][data-reload-interval]," +
        "[data-reload-url][data-reload-target-selector][data-reload-timeout]," +
        "[data-reload-url][data-reload-response-handler][data-reload-timeout]"
      ).each(attachReloadTimer);
    }

    function attachReloadTimer() {
      var
        $this = $(this),
        timeout = parseInt($this.data("reload-timeout")),
        interval = parseInt($this.data("reload-interval")),
        ms = false, type = false
      ;
      if (!isNaN(interval)) {
        ms = interval;
        type = "Interval";
      } else if (!isNaN(timeout)) {
        ms = timeout;
        type = "Timeout";
      }
      $this  //attach only once => remove
        .removeData(["reload-interval", "reload-timeout"])
        .removeAttr("data-reload-interval data-reload-timeout")
      ;
      this["dataReload" + type] = window["set" + type](function () {
        reloadUrl.call($this[0]);
      }, ms);
    }

    function reloadUrl(event) {
      var
        $this = $(this).addClass("data-reload-url--running").removeClass("data-reload-url--done"),
        url = $this.data('reload-url'),
        once = /^true$/i.test($this.data('reload-once')),
        targetSelector = $this.data('reload-target-selector'),
        responseHandlerName = $this.data('reload-response-handler'),
        waitClass = $this.data('reload-wait-class') || "data-reload-url--running",
        waitLock = /^true$/i.test($this.data("reload-wait-lock")) ? "reload-wait-lock" : "",
        executeDefault = /^true$/i.test($this.data('reload-execute-default')),
        historyUrl = $this.data('history-url'),
        $target = targetSelector ? $(targetSelector) : false,
        responseHandler = responseHandlerName ? Generic.util.retrieveFunction(responseHandlerName) : false,
        ajaxSettings = {
          complete: getReloadCompleteHandler($this, $target, waitClass, waitLock),
          success: getReloadSuccessHandler($this, responseHandler, $target, once, waitClass, historyUrl),
          error: getReloadErrorHandler($this)
        }
      ;

      if (!executeDefault && event && event.preventDefault instanceof Function) {
        event.preventDefault();
      }

      if (url !== '' && (typeof responseHandler === "function" || $target !== false)) {
        executeAjaxRequest.call(this, url, ajaxSettings, $target, waitClass, waitLock);
      }
    }

    function executeAjaxRequest(url, ajaxSettings, $target, waitClass, waitLock) {
      var $this = $(this);
      if (waitLock && $this.hasClass(waitLock)) {
        return;
      }
      $this.addClass(waitClass + " " + waitLock);
      if ($target) {
        $target.addClass(waitClass).removeClass("data-reload-url--done");
        ajaxSettings.dataType = "html";
      }
      jsonpSettings($this, ajaxSettings);
      $.ajax(url, ajaxSettings);
    }

    function getReloadCompleteHandler($this, $target, waitClass, waitLock) {
      return function () {
        if ($target) {
          $target.removeClass(waitClass + " " + waitLock);
        }
        $this.removeClass(waitClass + " " + waitLock);
      };
    }

    function getReloadSuccessHandler($this, responseHandler, $target, once, waitClass, historyUrl) {
      return function (response) {
        if (once) {
          $this.removeData("reload-url").removeAttr("data-reload-url");
        }
        $this.removeClass("data-reload-url--running").addClass("data-reload-url--done");
        if (typeof responseHandler !== "function" ||   // optional data-reload-response-handler
          responseHandler.apply($this[0], arguments) !== false
        ) {
          if ($target) {
            handleReloadSuccessTarget($this, $target, response, waitClass);
          }
          if (historyUrl) {
            updateBrowserHistory(historyUrl);
          }
          $('body').trigger('ajaxreload');
        }
      };
    }

    function handleReloadSuccessTarget($this, $target, response, waitClass) {
      var replaceTarget;
      if (isAnHtmlPage(response)) {
        handleAsyncHtmlPageResponse(response);
      } else {
        replaceTarget = /^true$/i.test($this.data("reload-target-replace"));
        if (replaceTarget) {
          $target.replaceWith(response);
        } else {
          $target.html(response).removeClass(waitClass).addClass("data-reload-url--done");
        }
      }
    }

    function getReloadErrorHandler($this) {
      return function (xhr) {
        var params = [];
        if (typeof xhr !== "undefined" && !isNaN(xhr.status)) {
          params.push(xhr.status);
        }
        handleError.apply($this[0], params);
      };
    }

    function jsonpSettings($this, ajaxSettings) {
      var
        responseJsonp = $this.data('reload-response-jsonp'),
        responseJsonpCallback = $this.data('reload-response-jsonp-callback'),
        hasJsonp = responseJsonp && !/^false$/i.test(responseJsonp),
        hasJsonpCallback = responseJsonpCallback && !/^false$/i.test(responseJsonpCallback),
        isJsonP = hasJsonp || hasJsonpCallback
      ;
      if (isJsonP) {
        ajaxSettings.dataType = "jsonp";
        if (hasJsonp) {
          if (!/^true$/i.test(responseJsonp)) {
            ajaxSettings.jsonp = responseJsonp;
          }
        } else {
          ajaxSettings.jsonp = false;
        }
        if (hasJsonpCallback && !/^true$/i.test(responseJsonpCallback)) {
          ajaxSettings.jsonpCallback = responseJsonpCallback;
        }
      }
    }

    function updateBrowserHistory(url) {
      Generic.history.set(url);
    }

    function removeDuplicateObjects(array) {
      const unique = new Map();

      array.forEach(obj => {
        const key = JSON.stringify(obj);
        if (!unique.has(key)) {
          unique.set(key, obj);
        }
      });

      return Array.from(unique.values());
    }

    function runFormAsync(e) {
      e.preventDefault();
      e.stopPropagation();
      e.stopImmediatePropagation();
      let
        $form = $(this),
        url = $form.data("ajax-url") || "?",
        settings,
        onSubmit = retrieveCallback($form, "ajax-on-submit"),
        waitClass = $form.data("ajax-wait-class") || "data-ajax-url--running",
        waitLock = /^true$/i.test($form.data("ajax-wait-lock")) ? "ajax-wait-lock" : "",
        successTarget = $form.data("ajax-on-xhr-success-target"),
        abort = false
      ;
      try {
        settings = retrieveSettings($form);
      } catch (e) {
        if (e instanceof abortMessage) {
          abort = true;
        } else {
          throw e;
        }
      }
      if (!abort && !inWaitLock($form, waitLock) && (false === onSubmit || false !== onSubmit.call(this, e))) {
        $form.addClass(waitClass + " " + waitLock);
        setWaitClassForTargets($form);
        if (successTarget && waitClass) {
          $(successTarget).addClass(waitClass);
        }
        $.ajax(url, settings);
      }
      return false;
    }

    function inWaitLock($form, waitLock) {
      return waitLock && $form.hasClass(waitLock);
    }

    function setWaitClassForTargets($form) {
      const targets = $form.data('ajax-wait-class-for')?.split(',');

      if (!targets) return;

      targets.forEach(target => {
        $(target).addClass(`loading-because-${$form.attr('id')}`);
      });
    }

    function removeWaitClassForTargets($form) {
      const targets = $form.data('ajax-wait-class-for')?.split(',');

      if (!targets) return;

      targets.forEach(target => {
        $(target).removeClass(`loading-because-${$form.attr('id')}`);
      });
    }

    function retrieveSettings($form) {
      var settings = {
          data: collectAndSerializeFormData($form),
          method: $form.attr("method") || "GET",
          context: $form[0]
        },
        dataType = $form.data("ajax-dataType") || $form.data("ajax-data-type"),
        cache = !$form.attr("data-ajax-cache") || /^true$/i.test($form.data("ajax-cache"))
      ;
      if (dataType) {
        settings.dataType = dataType;
      }
      if (!cache) {
        settings.cache = false;
      }
      checkAndHandleMultipartFormData($form, settings);
      handleAjaxPlugins($form, settings);
      return $.extend(
        settings,
        retrieveAjaxCallbacks($form)
      );
    }

    // Function to collect and serialize form data from specified forms
    function collectAndSerializeFormData($form) {
      function selectorContainsBrackets(selector) {
        return selector && selector.includes('[') && selector.includes(']');
      }

      function selectorCanHoldFormValue(selector) {
        return $(selector).is('input') || $(selector).is('textarea');
      }

      // FormData from the form that triggers the ajax call
      function addOriginalFormData() {
        $form.serializeArray().forEach(item => formData.push(item));
      }

      function addAllForms() {
        forms.forEach(function (selector) {
          // Use the jQuery selector to find each element that matches the selector
          $(selector).each(function () {
            // Check if the current element is actually a form
            if ($(this).is('form')) {
              // Serialize the form data if the element is a form
              const formArray = $(this).serializeArray();

              // Add the serialized data from this form to the formData array
              formArray.forEach(function (item) {
                formData.push(item);
              });
            }
          });
        });
      }

      function addAllInputs() {
        inputs.forEach(function (selector) {
          if (selectorContainsBrackets(selector)) {
            const strippedSelector = selector.replace(/\[.*?]/g, ''); // actual css selector needed | removes []
            if (!selectorCanHoldFormValue(strippedSelector)) return;

            const dataAttributeWithinBrackets = selector.match(/\[(.*?)]/)[1]; // [code] => code
            const inputElement = $(strippedSelector);
            const elementName = inputElement.attr('name'); // foo
            const elementValueForSpecificKey = inputElement.data(dataAttributeWithinBrackets); // bar

            // Filter out already included form attributes that are in the form due to standard serialization => Remove standard, add custom
            formData = formData.filter((formAttribute) => formAttribute.name !== elementName);

            formData.push({name: elementName, value: elementValueForSpecificKey});

          } else {
            if (!selectorCanHoldFormValue(selector)) return;

            const serialized = $(selector).serializeArray()[0];

            formData.push(serialized);
          }
        });
      }

      function addUrlParams() {
        const searchParams = new URLSearchParams(window.location.search);

        for (const [key, value] of searchParams.entries()) {
          formData.push({name: key, value: value});
        }
      }

      // Initialize an empty array to hold the form data from all matching data sources
      let formData = [];
      const doAddOriginalFormData = $form.data('ajax-on-submit-add-original-form-data') !== false;
      const doMergeUrlParams = $form.data('ajax-on-submit-merge-url-params') || false;
      const forms = $form.data('ajax-on-submit-merge-forms') ? $form.data('ajax-on-submit-merge-forms').split(',') : [];
      const inputs = $form.data('ajax-on-submit-merge-inputs') ? $form.data('ajax-on-submit-merge-inputs').split(',') : [];
      const mergeFunc = $form.data('ajax-on-submit-merge-function') || undefined;

      if (doAddOriginalFormData) {
        addOriginalFormData(); // adds form inputs of original form
      }
      addAllForms(); // all forms specified in 'merge-forms'
      addAllInputs(); // all inputs specified in 'merge-inputs'

      if (mergeFunc) {
        try {
          const result = Generic.util.retrieveFunction(mergeFunc).apply();

          formData.push(...result);
        } catch (e) {
        }
      }

      if (doMergeUrlParams) {
        addUrlParams();
      }

      // Return the merged and serialized form data array
      return removeDuplicateObjects(formData);
    }

    function handleAjaxPlugins($form, settings) {
      $form.find("[data-ajax-plugin]").each(function () {
        handleAjaxPlugin.call(this, settings);
      });
    }

    function handleAjaxPlugin(settings) {
      var func = retrieveCallback($(this), "ajax-plugin");
      if (func) {
        func.call(this, settings);
      }
    }

    function retrieveAjaxCallbacks($form) {
      var
        successNotification = $form.data("ajax-on-xhr-success-notification"),
        successTarget = $form.data("ajax-on-xhr-success-target"),
        callbacks = {
          error: function (xhr) {
            var params = [];
            if (typeof xhr !== "undefined" && !isNaN(xhr.status)) {
              params.push(xhr.status);
            }
            handleError.apply($form[0], params);
            if (typeof $form.data("ajax-on-xhr-error-form-replace") !== "undefined") {
              checkErrorFormResponse.call($form[0], xhr);
            }
          }
        },
        callback,
        successCallback,
        completeCallback,
        waitClass = $form.data("ajax-wait-class") || "data-ajax-url--running",
        waitLock = /^true$/i.test($form.data("ajax-wait-lock")) ? "ajax-wait-lock" : ""
      ;
      $.each(["success", "error", "complete"], function (i, callbackName) {
        callback = retrieveCallback($form, "ajax-on-xhr-" + callbackName);
        if (callback) {
          callbacks[callbackName] = callback;
        }
      });
      successCallback = callbacks.success;
      callbacks.success = function (data) {
        if (!successCallback || false !== successCallback.apply(this, arguments)) {
          if (/^true$/.test($(this).data("ajax-once"))) {
            $(this).removeAttr("[data-reload-url]");
          }
          if (successNotification) {
            Generic.notification.create(successNotification);
          }
          if (successTarget) {
            if (isAnHtmlPage(data)) {
              handleAsyncHtmlPageResponse(data);
            } else {
              handleSuccessTargetContent($form, $(successTarget), data);
            }
            $('body').trigger('ajaxreload');
          }
        }
      };
      completeCallback = callbacks.complete;
      callbacks.complete = function (data) {
        if (completeCallback) {
          completeCallback.apply(this, arguments);
        }
        if (successTarget) {
          $(successTarget).removeClass(waitClass);
        }
        $form.removeClass(waitClass + " " + waitLock);
        removeWaitClassForTargets($form);
      };
      return callbacks;
    }

    function retrieveCallback($form, callbackFullName) {
      var callbackPath = $form.data(callbackFullName);
      if (callbackPath) {
        return Generic.util.retrieveFunction(callbackPath);
      }
      return false;
    }

    function checkErrorFormResponse(xhr) {
      if (/\s*<form\b/i.test(xhr.responseText)) {
        $(this).replaceWith(xhr.responseText);
        $("body").trigger("ajaxreload");
      }
    }

    function checkAndHandleMultipartFormData($form, settings) {
      if (hasMultiPartFormData($form)) {
        settings.data = new FormData($form[0]);
        settings.contentType = false;
        settings.processData = false;
        settings.cache = false;
        settings.enctype = 'multipart/form-data';
        settings.async = true;
      }
    }

    function hasMultiPartFormData($form) {
      return /^\s*multipart\s*\/\s*form-data\s*$/i.test($form.attr("enctype"));
    }

    function handleSuccessTargetContent($form, $target, data) {
      var replaceTarget = /^true$/i.test($form.data("ajax-on-xhr-success-target-replace"));
      if (replaceTarget) {
        $target.replaceWith(data);
      } else {
        $target.html(data);
      }
    }

    function handleError(type, cmd) {
      var url, text, $this = $(this), $notification, timeout = 0;
      if (typeof cmd === "undefined") {
        cmd = $this.data("reload-url") ? "reload" : "ajax";
      }
      if (typeof type !== "undefined" && type !== 0) {
        url = $this.data(cmd + "-on-http-" + (type ? type : "error") + "-redirect-url") ||
          $this.data(cmd + "-on-http-error-redirect-url");
        text = $this.data(cmd + "-on-http-" + (type ? type : "error") + "-notification") ||
          $this.data(cmd + "-on-http-error-notification");
      }
      if (text) {
        $notification = Generic.notification.create(text, "error");
        timeout = $notification.data("notification-timeout");
      }
      if (url) {
        window.setTimeout(function () {
          window.location.href = handleRedirectUrl(url, type);
        }, timeout);
      }
    }

    /**
     * attach infos to the redirect like original referrer and cause
     * @param url
     * @param type
     * @return {string}
     */
    function handleRedirectUrl(url, type) {
      var
        hash = url.replace(/^[^#]+/, ""),
        query = url.replace(/^[^?]+|#[^#]*$/, "")
      ;
      url = url.replace(/[?#].*$/, "");
      if (query.length) {
        if (/^\?\w+/.test(query)) {
          query += "&";
        }
      } else {
        query = "?";
      }
      return url + query + "redirectUrlCause=" + (type ? type : "error") + "&redirectUrlReferrer=" + encodeURIComponent(window.location.href) + hash;
    }

    function isAnHtmlPage(response) {
      return /<html\b/i.test(response.substr(0, 1000));
    }

    function handleAsyncHtmlPageResponse(response) {
      var
        $body = $("body"),
        $title = $("html head title"),
        title = $(response.replace(/^[\s\S]*?<title[^>]*>([\s\S]*?)<\/title[^>]*>[\s\S]*$/i, "<div>$1</div>")).text().trim(),
        $newBody = $(response.replace(/^[\s\S]*?<body([^>]*)>([\s\S]*?)<\/body[^>]*>[\s\S]*$/i, "<div$1>$2</div>"))
      ;
      if (title) {
        $title.text(title);
      }
      if ($newBody) {
        transferHtmlElementAttributes($newBody, $body);
      }
      transferServeletUrl(response);
      transferDomWithoutScriptsAndStyles($newBody, $body);
    }

    function transferHtmlElementAttributes($source, $target) {
      var
        sourceAttrs = $source[0].attributes,
        targetAttrs = $target[0].attributes,
        a
      ;
      for (a = 0; a < targetAttrs.length; a++) {
        $target.removeAttr(targetAttrs[a].name);
      }
      for (a = 0; a < sourceAttrs.length; a++) {
        $target.attr(sourceAttrs[a].name, sourceAttrs[a].value);
      }
    }

    function transferServeletUrl(response) {
      var
        match = /<meta\s[^>]*?data-custom-name="hybris-servlet-path"[^>]*?\/>/.exec(response),
        url = match && match[0] ? $(match[0]).data("custom-content") : false
      ;
      if (url) {
        Generic.history.set(url);
      }
    }

    function transferDomWithoutScriptsAndStyles($source, $target) {
      var $children = $source.children().filter(":not(script, style)");
      if ($children.length) {
        $target.children().remove();
        $target.append($children);
      }
    }

    function abortMessage() {
    }
  })()
}, {}, Generic || {});
