(function () {
  "use strict";
  $(function () {
    searchToggles();
    $("body").on("ajaxreload", searchToggles);
  });

  function searchToggles() {
    ["click", "submit"].forEach(searchEventToggles);
  }

  function searchEventToggles(eventName) {
    //Todo: Extend Toggle with data-toogle-collapse-selector (fold all other - keep only one open)
    //Todo: click-toggle-js-call - callback-function instead of className
    $("[data-" + eventName + "-toggle-cssclass]:not(.event-toggle-attached)"/*+",[data-click-toggle-js-call]"*/)
      .addClass("event-toggle-attached")
      .each(function () {
        var
          $this = $(this),
          className = $this.data(eventName + "-toggle-cssclass"),
          labelSelector = $this.data(eventName + "-toggle-on-selector"),
          labelAbsoluteSelector = $this.data(eventName + "-toggle-on-absolute-selector"),
          targetSelector = $this.data(eventName + "-toggle-target-selector"),
          targetAbsoluteSelector = $this.data(eventName + "-toggle-target-absolute-selector"),
          active = /^true$/i.test($this.data(eventName + "-toggle-dom-ready-active")),
          $labels = selectObj($this, labelSelector, labelAbsoluteSelector, $this)
        ;
        $labels.each(function () {
          var $label = $(this);
          if (!$label.data("dataEventToggleAttached")) {
            $label.on(eventName, eventHandler).prop("dataEventToggleAttached", true);
          }
        });

        function eventHandler() {
          if (className) {
            selectObj($this, targetSelector, targetAbsoluteSelector, $this)
              .toggleClass(className);
            $(window).trigger("resize");
          }
        }

        if (active) {
          eventHandler();
        }
      });
  }

  function selectObj($context, relativeSelector, absoluteSelector, $default) {
    var $ret = $();
    if (relativeSelector) {
      $ret = $ret.add(relativeSelector, $context);
    }
    if (absoluteSelector) {
      $ret = $ret.add(absoluteSelector);
    }
    if (!$ret.length && $default instanceof $) {
      $ret = $ret.add($default);
    }
    return $ret;
  }
})();

