KPlusS = Object.assign({}, KPlusS || {}, (function () {
  return {
    customerService: {
      contact: {
        sendMail
      }
    }
  }

  function sendMail() {
    const $emailButton = $(this)
    const emailAddress = $emailButton.data("mail-address")
    const subjectTranslation = $emailButton.data("mail-subject")
    const orderCode = $(".order-detail-overview #order-id").text() // Order number in order detail header

    window.location.href =
      !!subjectTranslation
        ? !!orderCode
          ? `mailto:${emailAddress}?subject=${subjectTranslation} #${orderCode}`
          : `mailto:${emailAddress}?subject=${subjectTranslation}`
        : `mailto:${emailAddress}`;
  }
})())
