$(function () {
  "use strict";
  var $body = $("body");
  $body
    .on("dblclick", "[data-on-click-call]", function (e) {
      e.stopImmediatePropagation();
    })
    .on("keydown keypress", ":focus", function (e) {
      if (e.which === 13 || e.keyCode === 13 || e.key === 13) {
        $(this).trigger(e.type + "-enter");
      }

      if (e.which === 32 || e.keyCode === 32 || e.key === 32) {
        $(this).trigger(e.type + "-space");
      }
    })
  ;

  $(["click", "mouseover", "mouseenter", "mousedown", "change", "datepicker-selected", "keydown-enter", "keydown-space", "keypress-enter", "keypress-space", "keydown", "keyup", "keypress",
    "submit", "select2:selecting", "select2:unselecting", "filtered", "active", "inactive", "input", "focusout", "blur"])
    .each(function (i, eventName) {
      var attrName = eventName.replace(/[^\w\-]/, "-");
      $body.on(eventName, "[data-on-" + attrName + "-call]", function (e) {
        var
          funcName = $(this).data("on-" + attrName + "-call"),
          func = funcName && Generic.util.retrieveFunction(funcName)
        ;

        if (typeof func === "function") {
          func.call(this, e);
        }
      });
    });

  $body.on("click", "[data-on-click-submit-selector],[data-on-click-submit-absolute-selector]", function () {
    var $this = $(this), relativeSelector, absoluteSelector, $form;
    if (!$this.is(":disabled")) {
      relativeSelector = $this.data("on-click-submit-selector");
      absoluteSelector = $this.data("on-click-submit-absolute-selector");
      if (absoluteSelector) {
        $form = $(absoluteSelector);
      } else {
        $form = $this.find(relativeSelector);
      }
      $form.trigger("submit");
    }
  });

  checkDomReadyClick();
  $body.on("ajaxreload", checkDomReadyClick);

  function checkDomReadyClick() {
    $("[data-on-dom-ready-click]:not(.dom-ready-clicked)")
      .addClass("dom-ready-clicked")
      .each(function () {
        var
          $this = $(this),
          absSelector = $this.data("on-dom-ready-click"),
          $target = absSelector ? $(absSelector) : $this
        ;
        $target.trigger("click");
      });
  }

  // with target
  attachDataOnChangeCallbacks();
  $body.on("ajaxreload", attachDataOnChangeCallbacks);

  function attachDataOnChangeCallbacks() {
    $("[data-on-change-callback]:not(.on-change-callback-attached)")
      .addClass("on-change-callback-attached")
      .each(attachDataOnCallback);
  }

  function attachDataOnCallback() {
    var
      $this = $(this),
      callback = Generic.util.retrieveFunction($this.data("on-change-callback")),
      $target
    ;
    if ($this.is("[data-on-change-source-absolute-selector]")) {
      $target = $($this.data("on-change-source-absolute-selector"));
    } else if ($this.is("[data-on-change-source-selector]")) {
      $target = $this.find($this.data("on-change-source-selector"));
    } else {
      $target = $this;
    }
    $target.on("change", callback);
  }

  $("[data-on-invalid]").each(function () {
    var
      $this = $(this),
      onInvalid = $(this).data("on-invalid"),
      func = onInvalid ? Generic.util.retrieveFunction(onInvalid) : null
    ;
    if (func) {
      $this.on("invalid", func);
    }
  });

  attachFormSubmitsHandlers();
  $body.on("ajaxreload", attachFormSubmitsHandlers);

  function attachFormSubmitsHandlers() {
    $("form[data-on-submit-call]:not(.data-on-submit-attached)")
      .addClass("data-on-submit-attached")
      .on("submit", function (e) {
        var
          funcPath = $(this).data("on-submit-call"),
          func = funcPath && Generic.util.retrieveFunction(funcPath)
        ;
        if (func) {
          func.call(this, e);
        }
      });
    $("form[data-on-submit-class]:not(.data-on-submit-class-attached)")
      .addClass("data-on-submit-class-attached")
      .on("submit", function (e) {
        $(this).addClass($(this).data("on-submit-class"));
      });
  }

  checkOnDomReadyTrigger();
  $body.on("ajaxreload", checkOnDomReadyTrigger);

  function checkOnDomReadyTrigger() {
    window.requestAnimationFrame(function () {
      $("[data-on-dom-ready-trigger]:not(.dom-ready-triggered)")
        .addClass("dom-ready-triggered")
        .each(triggerOnDomReady);
    });
  }

  function triggerOnDomReady() {
    var
      $this = $(this),
      eventName = $this.data("on-dom-ready-trigger"),
      targetRelative = $this.data("on-dom-ready-trigger-selector"),
      targetAbsolute = $this.data("on-dom-ready-trigger-absolute-selector"),
      $target = $this
    ;
    if (targetRelative || targetAbsolute) {
      $target = targetRelative ? $this.find(targetRelative) : $(targetAbsolute);
    }
    $target.trigger(eventName);
  }
});
