KPlusS = Object.assign((function () {
  let activeCartEntryId = null;
  let $pendingDelivery = undefined;

  const DATEPICKER_CHANGE_EVENT = 'datepicker-selected';
  const TYPEAHEAD_CHANGE_EVENT = 'typeahead-selected';
  const PRODUCT_QUANTITY_CHANGE_EVENT = 'productQuantity-change';
  const TAB_CHANGE_EVENT = 'tab-change';
  const DELIVERY_LIST_QUANTITY_CHANGE_EVENT = 'deliveryList-change';

  const $deliveryOptionsPage = $('.lsg.ks-delivery-options');
  const $deliveryOfferPage = $('.lsg.ks-delivery-offer');
  const $deliveryOfferFragment = $deliveryOfferPage.find('#ks-delivery-offer-fragment');
  const $deliveryPageNextButton = $deliveryOptionsPage.find("#js-next-btn");


  addEventListener("DOMContentLoaded", () => {
    registerDeliveryOptionChangeDetection();
    registerDeliveryOfferChangeDetection();
  });

  return {
    delivery: {
      components: { // Page agnostic
        productSelection: {
          modal: {
            openDeleteModal,
            closeDeleteModal,
          },
          deleteProduct,
          updateProduct,
          handleQuantityChange,
        },
      },
      pages: { // For specific pages
        options: {
          form: {
            handleXhrComplete: handleXhrCompleteForOptions,
            composePayload: composePayloadForOptions
          },
          tabs: {
            togglePickup: togglePickupForOptions
          },
          selectTransportOption,
          goToOfferPage
        },
        offer: {
          form: {
            handleXhrComplete: handleXhrCompleteForOffer,
            composePayload: composePayloadForOffer
          },
          goToCheckoutPage,
          toggleDeliveryDeletionModal,
          deleteDelivery,
          addDelivery
        },
        checkout: {
          form: {
            toggleLoadingState: toggleLoadingStateForCheckout
          },
          billTo: {
            updateBillTo,
            modal: {
              toggleBillToSelectionModal
            }
          }
        }
      },
    }
  };

  function addDelivery() {
    const $allDeliveryDays = $deliveryOfferPage.find(".ks-delivery-list-entry");
    const allDeliveryDates = Array.from($(".js-hidden-date").map((index, entry) => $(entry).val())); // ["2024-03-11", "2024-03-15", "2024-07-05", ...]
    const farthestFutureDateIndex = allDeliveryDates?.indexOf(getFarthestFutureDate(allDeliveryDates));
    const $clonedDeliveryDay = createClonedDeliveryDay($allDeliveryDays?.eq(farthestFutureDateIndex));

    // Add cloned delivery day at the bottom of the list
    $deliveryOfferPage.find('.ks-delivery-list #js-delivery-wrapper').append($clonedDeliveryDay);

    setupPickers();
    patchDeliveryNumbers();
    hidePriceInformation();

    $(this).trigger(DELIVERY_LIST_QUANTITY_CHANGE_EVENT);

    function hidePriceInformation() {
      const products = $clonedDeliveryDay.find('.js-cart-entry');

      products.each(function () {
        $(this).find('.ks-delivery-list-entry-item__price--per-unit').remove();
        $(this).find('.ks-delivery-list-entry-item__price--total').remove();
      });
    }

    function patchDeliveryNumbers() {
      const deliveryNumbers = $deliveryOfferPage.find(".ks-delivery-list-entry__count");

      deliveryNumbers.each(function () {
        let numbers = $(this).text().split(' / ') || [];

        if (!numbers.length || !$allDeliveryDays.length) return;

        $(this).text(`${numbers.at(0)} / ${$allDeliveryDays.length + 1}`);
      });
    }

    function setupPickers() {
      $clonedDeliveryDay.find(".ks-delivery-list-entry-item").each(function () { // Setup quantity pickers
        const $container = $(this);

        if ($container) {
          const $stepper = $container.find(".with-stepper");
          const $stepQuantity = $(this).find(".js-step-quantity");

          ACC.form.activateNumericStepper($container, $stepper, $stepQuantity.data("step-quantity"));
        }
      });
      ACC.datepicker.setupDatePickerWithRestriction($clonedDeliveryDay.find(".js-datepicker"), $clonedDeliveryDay.find(".js-hidden-date")); // Setup datepicker
    }
  }

  function addMessageIfNeeded($target) {
    const $deliveryDay = $target.closest('.ks-delivery-list-entry');
    const $deliveryDayHeader = $deliveryDay.find('.ks-delivery-list-entry__information-header');
    const $deliveryDayQuantityStepper = $deliveryDay.find('.js-item-quantity');
    const localizedMessageText = $deliveryDay.data('emptyMessageText');

    const quantityIsZeroList = Array.from( // Array of all quantity values when something changes
      $deliveryDayQuantityStepper.map((index, quantityStepper) => {
        return +$(quantityStepper).find('.js-raw-quantity').val();
      })
    );

    if (quantityIsZeroList.every((quantityValue) => quantityValue === 0)) { // When every quantity in a delivery is 0
      if ($deliveryDay.find('.ks-messages--temp').length !== 0) return;

      $deliveryDayHeader.after(
        `<div class="ks-messages--temp ks-messages--in-delivery">
          <div class="ks-message ks-message--warning">
            <div class="ks-message__icon-wrapper">
              <i class="icon icon-info"></i>
            </div>
            <span>${localizedMessageText}</span>
            </div>
        </div>`
      );

    } else {
      $deliveryDay.find('.ks-messages--temp').remove();
    }
  }

  /**
   * Returns `true` if any value in the provided map values are true
   * @returns {boolean} `true` if any product quantity has changed, otherwise false.
   * @param changes {Map}
   */
  function anythingChanged(changes) {
    return Array.from(changes.values()).some(Boolean);
  }

  function closeDeleteModal() {
    $('.ks-tab-pane.active #deleteModal').modal('hide');
  }

  function composePayloadForOffer() {
    let formData = [];
    const $allCartEntriesFromAllDays = $deliveryOfferFragment.find('.js-cart-entry');
    const urlParams = new URLSearchParams(window.location.search);
    const pickup = urlParams.get('isPickup');

    formData.push({name: `pickup`, value: pickup});

    $allCartEntriesFromAllDays.each((index, entry) => {
      const $entry = $(entry);
      const entryDeliveryDate = $entry.closest('.ks-delivery-list-entry').find('#preferredDeliveryDate').val();
      const entryQuantity = +$entry.find('.js-raw-quantity').val();
      const entryNumber = $entry.find('.js-entryNumber').val();
      const entryProductCode = $entry.find('.js-productCode').val();
      const entryUnitCode = $entry.find('.js-unitCode').val();

      formData.push({name: `cartEntries[${index}].entryNumber`, value: entryNumber});
      formData.push({name: `cartEntries[${index}].quantity`, value: entryQuantity});
      formData.push({name: `cartEntries[${index}].productCode`, value: entryProductCode});
      formData.push({name: `cartEntries[${index}].unitCode`, value: entryUnitCode});
      formData.push({name: `cartEntries[${index}].deliveryDate`, value: entryDeliveryDate});
    });

    return formData;
  }

  function createClonedDeliveryDay($farthestFutureDeliveryDay) {
    const allDeliveryDays = $deliveryOfferPage.find(".ks-delivery-list-entry");

    function addDayToClonedDatepicker(clonedDeliveryDay) {
      const $datepicker = clonedDeliveryDay.find('.js-datepicker');
      const actualDate = new Date($datepicker.closest('.preferred-delivery-date-component').find("#preferredDeliveryDate").val());
      const datepickerConfig = $datepicker.data("dates");

      $datepicker.data('dates').initialDeliveryDate = addDay(actualDate);

      return clonedDeliveryDay;

      /**
       * Adds one day to a given date, skipping Sundays.
       * @param {Date} date - The original date.
       * @returns {string} - The new date, with one day added, skipping Sundays.
       */
      function addDay(date) {
        const ONE_DAY = (1000 * 60 * 60 * 24); // (milliseconds, seconds, minutes, hours)
        const newDate = new Date(date.getTime());

        newDate.setTime(newDate.getTime() + ONE_DAY);

        while (nextDayIsBlocked()) {
          newDate.setTime(newDate.getTime() + ONE_DAY);
        }

        return newDate.toISOString().slice(0, 10);

        function nextDayIsBlocked() {
          const dayNameMapping = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];
          const {disabledWeekdays, noDeliveryDays, onlyAllowedWeekdays} = datepickerConfig;
          let newDateIsInOnlyAllowedWeekdays = false;
          let newDateIsInDisabledWeekdays = false;
          let newDateIsInNoDeliveryDays = false;

          if (disabledWeekdays?.length) {
            newDateIsInDisabledWeekdays = disabledWeekdays.some((weekday) => {
              return dayNameMapping.indexOf(weekday) === newDate.getDay();
            });
          }

          if (onlyAllowedWeekdays?.length) {
            newDateIsInOnlyAllowedWeekdays = onlyAllowedWeekdays.some((weekday) => {
              return (dayNameMapping.indexOf(weekday) === newDate.getDay());
            });
          }

          if (noDeliveryDays?.length) {
            newDateIsInNoDeliveryDays = noDeliveryDays.some((dateObj) => {
              return (new Date(dateObj.day).getTime() === newDate.getTime());
            });
          }

          return !!onlyAllowedWeekdays?.length
            ? !newDateIsInOnlyAllowedWeekdays || newDateIsInNoDeliveryDays
            : newDateIsInDisabledWeekdays || newDateIsInNoDeliveryDays;
        }
      }
    }

    function removeMessages(clonedDeliveryDay) {
      clonedDeliveryDay.find(".ks-messages").remove();
      return clonedDeliveryDay;
    }

    function setNumberOfDeliveriesToUnknown(clonedDeliveryDay) {
      clonedDeliveryDay.find(".ks-delivery-list-entry__number-of-deliveries span").text("?");
      return clonedDeliveryDay;
    }

    function setCorrectDeliveryCount(clonedDeliveryDay) {
      clonedDeliveryDay.find(".ks-delivery-list-entry__count").text(`${allDeliveryDays.length + 1} / ${allDeliveryDays.length + 1}`);
      return clonedDeliveryDay;
    }

    function setAllQuantitiesToZero(clonedDeliveryDay) {
      clonedDeliveryDay.find('.js-raw-quantity').each(function () { // Actual values as data source
        $(this).val(0);
      });
      clonedDeliveryDay.find('.js-stepper-input').each(function () { // Display values in stepper component
        $(this).data('initial-value', 0);
      });


      return clonedDeliveryDay;
    }

    function resetClonedDatepicker(clonedDeliveryDay) {
      clonedDeliveryDay.find('.js-datepicker')
        .removeClass("hasDatepicker")
        .removeAttr("id")
        .removeData('datepicker')
        .unbind();
      return clonedDeliveryDay;
    }

    // Everything need to properly setup the "new" delivery day
    const composeNewDeliveryDay = Generic.util.Function.pipe(
      setAllQuantitiesToZero,
      setNumberOfDeliveriesToUnknown,
      setCorrectDeliveryCount,
      resetClonedDatepicker,
      addDayToClonedDatepicker,
      removeMessages,
    );

    return composeNewDeliveryDay($farthestFutureDeliveryDay.clone());
  }

  function datepickerHaveBeenChanged() {
    const $datepickers = $(".js-datepicker");

    const allInitialValues = Array.from(
      $datepickers.map((index, datepicker) => {
        return $(datepicker).data('dates').initialDeliveryDate || '';
      }));

    const allNewValues = Array.from(
      $datepickers.map((index, datepicker) => {
        return $(datepicker).parent().find("#preferredDeliveryDate").val();
      }));

    return (JSON.stringify(allInitialValues) !== JSON.stringify(allNewValues)); // ['2024-03-15', '2024-03-20'] !== ['2024-03-21', '2024-03-28']
  }

  function deleteDelivery() {
    const $quantities = $pendingDelivery && $pendingDelivery.find('.js-raw-quantity');

    $quantities.each((index, quantity) => {
      $(quantity).val(0);
    });

    toggleDeliveryDeletionModal();
    $deliveryOfferPage.find('#deliveryOfferForm').submit();
  }

  function deleteProduct() {
    const $activeCartEntry = $(`.js-cart-entry[data-cart-entry-id=${activeCartEntryId}]`);
    const $pendingCartQuantity = $activeCartEntry.find('.js-raw-quantity');

    $pendingCartQuantity.val(0);

    $("#productSelectionForm").submit();
    closeDeleteModal();

    $('#waitModal').modal();
  }

  function formPointsToRecalculateEndpoint($form) {
    return !!$form.data('ajax-url')?.includes('recalculate') || false; // When the form already points to correct endpoint we don't have to patch it anymore.
  }

  function fragmentIsEmpty() {
    return $('div[id*="fragment"]').is(':empty');
  }

  function getFarthestFutureDate(dates) {
    if (!dates.length) return;

    return dates?.reduce((farthest, current) => {
      const farthestDate = new Date(farthest);
      const currentDate = new Date(current);
      return farthestDate > currentDate ? farthest : current;
    });
  }

  function goToCheckoutPage() {
    const searchParams = window.location.search;
    const nextUrl = $deliveryOfferPage.data('nextUrl');

    window.location.href = `${nextUrl}${searchParams}`;
  }

  function goToOfferPage() {
    const $transportFragment = $('.lsg.ks-delivery-transport-selection');
    const $selectedCard = $transportFragment.find('.ks-delivery-transport-card[data-is-selected="true"]');
    const simulationId = $selectedCard.data('simulationId');
    const pickupFlag = $deliveryOptionsPage.find('input#js-pickup-input').val();
    const params = {
      simulationId: simulationId,
      isPickup: pickupFlag
    };
    const nextUrl = $deliveryOptionsPage.data('nextUrl');
    const queryString = $.param(params);

    window.location.href = `${nextUrl}?${queryString}`;
  }

  function handleQuantityChange() {
    $(this).trigger(PRODUCT_QUANTITY_CHANGE_EVENT);
  }

  function handleXhrCompleteForOptions() {
    $(() => {
      setInitialPickupLocations();
      patchPreferredDeliveryDate();
      setNextButtonState();

      function patchPreferredDeliveryDate() {
        const $transportFragment = $('.lsg.ks-delivery-transport-selection');
        const newPreferredDate = $transportFragment.data('preferred-delivery-date');
        const newLastDate = $transportFragment.data('last-possible-delivery-date');

        if (!newPreferredDate || !newLastDate) return;

        const $datepicker = $deliveryOptionsPage.find('.preferred-delivery-date-component .js-datepicker');
        const dateObject = $datepicker.data('dates');
        $datepicker.datepicker("setDate", new Date(newPreferredDate));
        $datepicker.datepicker("option", "maxDate", new Date(newLastDate));
        $datepicker.data('dates', {
          ...dateObject,
          initialDeliveryDate: newPreferredDate,
          lastValidDeliveryDate: newLastDate
        });
      }

      function setNextButtonState() {
        const canContinueElement = $deliveryOptionsPage.find('[data-can-continue]');
        const canContinue = canContinueElement.length && canContinueElement.data('canContinue');
        const transportSelectionIsSelected = $deliveryOptionsPage.find('.ks-delivery-transport-card[data-is-selected="true"]').length;

        $deliveryOptionsPage.find("#js-calculate-btn").addClass("hidden");
        $deliveryOptionsPage.find("#js-next-btn").removeClass("hidden");
        $deliveryOptionsPage.find("#js-next-btn").prop("disabled", false);

        // Disable next button when the backend specified that we can't go to the delivery offer page.
        if (canContinueElement.length && !canContinue) {
          $deliveryOptionsPage.find("#js-next-btn").prop("disabled", true);
        }

        // Disable next button when no transport card is selected.
        if (!transportSelectionIsSelected) {
          $deliveryOptionsPage.find("#js-next-btn").prop("disabled", true);
        }
      }

      function setInitialPickupLocations() {
        const $activeTab = $('.ks-tab-pane.active');
        const $pickupLocationList = $activeTab.find('.js-typeahead') || [];

        if (!$pickupLocationList.length) return;

        $pickupLocationList.each((index, typeahead) => {
          const $typeahead = $(typeahead);
          const $typeaheadInputElement = $typeahead.find('input');
          const initialValue = $typeaheadInputElement.val();

          $typeaheadInputElement.data('initialValue', initialValue);
        });
      }
    });
  }

  function composePayloadForOptions() {
    let formData = [];
    const $activeTab = $('.ks-tab-pane.active');
    const $productSelectionList = $activeTab.find('#productSelectionForm');
    const $products = $productSelectionList.find('.js-cart-entry');

    $products.each((index, product) => {
      const $product = $(product);
      const $productIndex = $product.data('cart-entry-id');
      const entryNumber = $product.find(`[name="cartEntries[${$productIndex}].entryNumber"]`).val();
      const productCode = $product.find(`[name="cartEntries[${$productIndex}].productCode"]`).val();
      const quantity = $product.find(`[name="cartEntries[${$productIndex}].quantity"]`).val();
      const pickupPlantCode = $product.find(`[name="cartEntries[${$productIndex}].pickupPlantCode"]`)?.data('code') || null;

      formData.push({name: `cartEntries[${$productIndex}].entryNumber`, value: entryNumber});
      formData.push({name: `cartEntries[${$productIndex}].productCode`, value: productCode});
      formData.push({name: `cartEntries[${$productIndex}].quantity`, value: quantity});

      if (!pickupPlantCode) return;

      formData.push({name: `cartEntries[${$productIndex}].pickupPlantCode`, value: pickupPlantCode});
    });

    const $preferredDeliveryDate = $activeTab.find('#preferredDeliveryDate');
    const preferredDeliveryDate = $preferredDeliveryDate.val();

    formData.push({name: `preferredDeliveryDate`, value: preferredDeliveryDate});

    return formData;
  }

  function handleXhrCompleteForOffer() {
    $(() => {
      const $offerForm = $deliveryOfferPage.find('#deliveryOfferForm');
      const updatedCartEntries = $deliveryOfferPage.find('[data-updated-cart-entries]').data('updated-cart-entries') || [];

      setupPickers();
      setNextButtonState();
      patchGlobalCartEntries(updatedCartEntries);
      patchMiniCart(updatedCartEntries);
      patchFormForOfferRecalculate();
      patchSimulationId();

      //region Helper functions
      function allOrdersAreValid() {
        const allValidOrderBooleans = Array.from($('[data-is-valid-order]').map((_, el) => $(el).data('is-valid-order')));

        if (!allValidOrderBooleans.length) return false; // []

        return allValidOrderBooleans.every(Boolean); // [true, true] || [true, false]
      }

      function errorMessageIsPresent() {
        return !!$deliveryOfferPage.find('.ks-message--danger').length;
      }

      function setupPickers() {
        $(".ks-delivery-list-entry-item").each(function () {
          const $container = $(this);

          if ($container) {
            const $stepper = $container.find(".with-stepper");
            const $stepQuantity = $(this).find(".js-step-quantity");

            ACC.form.activateNumericStepper($container, $stepper, $stepQuantity.data("step-quantity"));
            $(this).trigger(PRODUCT_QUANTITY_CHANGE_EVENT);
          }
        });

        ACC.datepicker.setupDatepickerDefaultSettings();
        ACC.datepicker.initializeDatepicker();
      }

      function setNextButtonState() {
        $deliveryOfferPage.find("#js-calculate-btn").addClass("hidden");
        $deliveryOfferPage.find("#js-checkout-btn").removeClass("hidden");
        $deliveryOfferPage.find("#js-checkout-btn").prop("disabled", false);

        if (!allOrdersAreValid() || errorMessageIsPresent()) {
          $deliveryOfferPage.find("#js-checkout-btn").prop("disabled", true);
        }
      }

      function patchMiniCart(updatedCartEntries) {
        if (!updatedCartEntries?.length) return;

        $('.js-mini-cart-count > .nav-items-total').text(updatedCartEntries?.length);
      }

      function patchGlobalCartEntries() {
        const updatedCartEntries = $deliveryOfferPage.find('[data-updated-cart-entries]').data('updated-cart-entries') || [];
        const cartEntries = $deliveryOfferPage.find('.js-cart-list .js-cart-entry') || [];

        cartEntries.each((index) => {
          const entryCode = cartEntries.eq(index).find('.ks-product-selection-list-item__code .js-entry-code').text(); // e.g 71015
          const correspondingDeliveryListItem = $deliveryOfferPage.find('.ks-delivery-list-entry').first() // first delivery day contains every product
            .find(`.ks-delivery-list-entry-item__code>span:contains(${entryCode})`) // match by entry code (71015)
            .closest('.ks-delivery-list-entry-item'); // traverse up to get the entire element
          const correspondingPickupLocation = correspondingDeliveryListItem
            .find('.ks-delivery-list-entry-item__pickup-location span') // chosen pickup location by user
            .text(); // e.g. 'Picassent'

          cartEntries.eq(index).find('.ks-product-selection-list-item__pickup-location-value') // empty span with pickup location wrapped by skeleton loader
            .text(correspondingPickupLocation); // set text from correspondingPickupLocation

          if (!updatedCartEntries?.length) return;

          const correspondingUpdatedCartEntry = updatedCartEntries.find((updatedCartEntry) => updatedCartEntry.entryCode === entryCode);

          if (!correspondingUpdatedCartEntry) {
            cartEntries.eq(index).remove(); // Hide product when it's not in the current cart anymore.
          } else {
            cartEntries.eq(index).find('.js-raw-quantity').val(correspondingUpdatedCartEntry?.quantity);
            cartEntries.eq(index).find('[data-initial-value]').val(correspondingUpdatedCartEntry?.quantity);
          }
        });
      }

      /** ### Mutates delivery offer form
       *  #### Does change data ajax attributes in the given form to change the request from
       *  `/offer/initial` to `/offer/recalculate`
       *
       * @returns {void}
       */
      function patchFormForOfferRecalculate() {
        if (formPointsToRecalculateEndpoint($offerForm)) return;

        const newFormConfig = {
          method: 'POST',
          url: '/ksretail/delivery/offer/recalculate',
          mergeFunctionStr: 'KPlusS.delivery.pages.offer.form.composePayload',
          doAddOriginalFormData: false
        };

        $offerForm.prop('method', newFormConfig.method);
        $offerForm.data('ajax-url', newFormConfig.url);
        $offerForm.data('ajax-on-submit-merge-function', newFormConfig.mergeFunctionStr);
        $offerForm.data('ajax-on-submit-add-original-form-data', newFormConfig.doAddOriginalFormData);
      }

      //endregion
    });
  }

  /** Returns if the user is on specified delivery page or not
   *
   * @param pageName
   * @returns {boolean} Returns true if any product quantity has changed, otherwise false.
   */
  function isPage(pageName) {
    return !!window.location.pathname.includes(`delivery/${pageName}`);
  }

  /** Opens the delete modal for the selected product
   *
   * @param event
   */
  function openDeleteModal(event) {
    event.preventDefault();
    activeCartEntryId = $(this).closest(".js-cart-entry").data("cart-entry-id");
    $('.ks-tab-pane.active #deleteModal').modal();
  }

  /** Checks if any product quantities in the cart have been changed.
   *
   * This function compares the initial quantities of products in the cart with their current quantities.
   * @returns {boolean} Returns true if any product quantity has changed, otherwise false.
   */
  function productsHaveBeenChanged() {
    const $products = $('.js-cart-entry'); // Array of all products

    const allInitialValues = Array.from( // Array of all quantity values when the site loads
      $products.map((index, product) => {
        return +$(product).find('.js-stepper-input').data("initial-value");
      }));
    const allNewValues = Array.from( // Array of all quantity values when something changes
      $products.map((index, product) => {
        return +$(product).find('.js-raw-quantity').val();
      })
    );

    return (JSON.stringify(allInitialValues) !== JSON.stringify(allNewValues)); // [2,4] !== [2,7]
  }

  function pickupLocationsHaveBeenChanged() {
    const $pickupLocations = $('.ks-product-selection-list-item__pickup-location .js-typeahead');

    const allInitialValues = Array.from( // Array of all quantity values when the site loads
      $pickupLocations.map((index, pickupLocation) => {
        return $(pickupLocation).find('input').data("initialValue");
      }));
    const allNewValues = Array.from( // Array of all quantity values when something changes
      $pickupLocations.map((index, pickupLocation) => {
        return $(pickupLocation).find('input').val();
      })
    );

    return (JSON.stringify(allInitialValues) !== JSON.stringify(allNewValues)); // ['Setubal', 'Picassent'] !== ['Bebra', 'Setubal']
  }

  function allPickupLocationSelected() {
    const $activeTab = $('.ks-tab-link.active');
    const $activePane = $('.ks-tab-pane.active');
    const $productSelectionList = $activePane.find('#productSelectionForm');
    const $products = $productSelectionList.find('.js-cart-entry');

    const pickupLocationList = Array.from(
      $products.map((index, product) => {
        const cartIndex = $(product).data('cart-entry-id');
        return $(product).find(`[name="cartEntries[${cartIndex}].pickupPlantCode"]`).val() || undefined;
      })
    );

    const isPickup = $activeTab.data('target') === 'pickup';

    return isPickup
      ? pickupLocationList.length && $products.length === pickupLocationList.length
      : true;
  }

  /** Generic function which actives event listeners to events we want to observe.
   *
   * @param page {jQuery}
   * @param changes {Map}
   * @param changeEvent {string}
   */
  function registerChangeHandler(page, changes, changeEvent) {
    const $recalcButton = page.find("#js-calculate-btn");
    const $nextButton = page.find("#js-next-btn");
    const $checkoutButton = page.find("#js-checkout-btn");

    page.on(changeEvent, (event) => {
      const $target = $(event.target);
      const $activeTab = $('.ks-tab-pane.active');
      const isCalculatePossible = !$activeTab.find('#js-no-calculate').length;

      switch (event.type) {
        case DATEPICKER_CHANGE_EVENT: // When the preferred delivery date inputs/-s has/have changed
          changes.set("datepickerHasChanged", datepickerHaveBeenChanged());
          break;

        case PRODUCT_QUANTITY_CHANGE_EVENT: // When a product quantity is changed
          if (isPage("offer")) {
            togglePriceInformation($target); // hides / shows the price per unit & total price of product
            addMessageIfNeeded($target);
          }

          changes.set('productsHaveChanged', productsHaveBeenChanged());
          break;

        // Only delivery option page
        case TYPEAHEAD_CHANGE_EVENT: // when the pickup plant is changed
          if (isPage("options")) {
            changes.set('allPickupLocationSelected', pickupLocationsHaveBeenChanged());
          }
          break;

        // Only delivery offer page
        case DELIVERY_LIST_QUANTITY_CHANGE_EVENT:
          changes.set("deliveryListQuantityHasChanged", true);
          break;
      }

      if (anythingChanged(changes) || fragmentIsEmpty()) {
        $recalcButton.removeClass("hidden");
        $nextButton.addClass("hidden");
        $checkoutButton.addClass("hidden");
      } else {
        $recalcButton.addClass("hidden");
        $nextButton.removeClass("hidden");
        $checkoutButton.removeClass("hidden");
      }

      if (isPage("options")) {
        setTimeout(() => {
          allPickupLocationSelected() && isCalculatePossible
            ? $recalcButton.prop("disabled", false)
            : $recalcButton.prop("disabled", true);
        });
      }
    });
  }

  function registerDeliveryOfferChangeDetection() {
    if (!isPage("offer")) return;

    const changeToObserve = [DATEPICKER_CHANGE_EVENT, PRODUCT_QUANTITY_CHANGE_EVENT, DELIVERY_LIST_QUANTITY_CHANGE_EVENT];
    const changes = new Map();

    changeToObserve.forEach((changeEvent) => {
      registerChangeHandler($deliveryOfferPage, changes, changeEvent);
    });
  }

  function registerDeliveryOptionChangeDetection() {
    if (!isPage("option")) return;

    const changesToObserve = [DATEPICKER_CHANGE_EVENT, TYPEAHEAD_CHANGE_EVENT, PRODUCT_QUANTITY_CHANGE_EVENT, TAB_CHANGE_EVENT];
    const changes = new Map();

    changesToObserve.forEach((changeEvent) => {
      registerChangeHandler($deliveryOptionsPage, changes, changeEvent);
    });

    // Is needed for the change detection otherwise it does not register the current tab properly.
    $($deliveryOptionsPage).trigger(TAB_CHANGE_EVENT);
  }

  function selectTransportOption() {
    const $card = $(this);
    const $cardList = $card
      .closest('.ks-delivery-transport-selection') // Traversing up to transport selection component
      .find('.ks-delivery-transport-card'); // All cards (delivery + pickup)

    // De-selecting every card to be able to select only the clicked one
    $cardList.each((index, card) => {
      $(card).removeClass('ks-delivery-transport-card--active');
      $(card).attr('data-is-selected', false);
    });

    $card.addClass('ks-delivery-transport-card--active');
    $card.attr('data-is-selected', true);

    // Enable next button if it was disabled by the XhrCompleteHandler
    $deliveryPageNextButton.prop("disabled", false);
  }

  function toggleDeliveryDeletionModal(e) {
    const $modal = $deliveryOfferPage.find("#deliveryDeletionModal");
    const isOpen = !!$modal.length && $modal.attr('aria-hidden') === 'false';

    isOpen
      ? $modal.modal('hide')
      : $modal.modal('show');

    if (!e) return;
    $pendingDelivery = $(e.target).closest('.ks-delivery-list-entry');
  }

  function toggleLoadingStateForCheckout() {
    const checkoutForm = $("#checkoutForm")[0]; // Get the native element

    if (!checkoutForm.checkValidity()) return;

    const $button = $(this);
    const $buttonText = $button.find(".ks-checkout-navigation__place-order-text");
    const $buttonSpinner = $button.find(".ks-spinner__wrapper");

    $button.addClass("ks-checkout-navigation__place-order--readonly");
    $buttonText.addClass("hidden");
    $buttonSpinner.removeClass("hidden");
  }

  function togglePickupForOptions() {
    const clickedTab = $(this).data('target');
    const $isPickupInput = $('#js-pickup-input');
    const $calcButton = $deliveryOptionsPage.find("#js-calculate-btn");
    const $nextButton = $deliveryOptionsPage.find("#js-next-btn");
    const $checkoutButton = $deliveryOptionsPage.find("#js-checkout-btn");
    const $fragmentResponse = $deliveryOptionsPage.find('#ks-delivery-options-fragment');

    (clickedTab === 'pickup')
      ? $isPickupInput.val(true)
      : $isPickupInput.val(false);


    $fragmentResponse.empty();
    $calcButton.removeClass("hidden");
    $nextButton.addClass("hidden");
    $checkoutButton.addClass("hidden");
  }

  /** Sets the display state for price per unit and total price
   *
   * @param $target {jQuery}
   */
  function togglePriceInformation($target) {
    const $product = $target.closest('.js-cart-entry');
    const initialValue = +$product.find('.js-stepper-input').data("initial-value");
    const newValue = +$product.find('.js-raw-quantity').val();
    const $pricePerUnit = $product.find('.ks-delivery-list-entry-item__price--per-unit');
    const $priceTotal = $product.find('.ks-delivery-list-entry-item__price--total');

    if (initialValue !== newValue) {
      $pricePerUnit.addClass("hidden");
      $priceTotal.addClass("hidden");
    } else {
      $pricePerUnit.removeClass("hidden");
      $priceTotal.removeClass("hidden");
    }
  }

  function updateProduct() {
    $('#waitModal').modal();
    $("#productSelectionForm").submit();
  }

  function patchSimulationId() {
    const newSimId = $('[data-simulation-id]').data('simulation-id');

    if (!newSimId) return;

    const url = new URL(location);

    url.searchParams.set("simulationId", newSimId);
    history.replaceState({}, "", url);
  }

  function toggleBillToSelectionModal() {
    const $modal = $("#billToSelectionModal");
    const isOpen = !!$modal.length && $modal.attr('aria-hidden') === 'false';

    isOpen
      ? $modal.modal('hide')
      : $modal.modal('show');
  }

  function updateBillTo() {

    const billToPartyId = $(this).find("div[data-bill-to-party]").data('billToParty');

    $.ajax({
      type: 'POST',
      url: $("div[data-update-bill-to-ajax-url]").data("updateBillToAjaxUrl"),
      data: {
        billToParty: billToPartyId
      },
      success: () => window.location.reload()
    });
  }


})(), {}, KPlusS || {});
