$(function () {
  "use strict";

  addEventListener('DOMContentLoaded', () => {
    const tabs = document.querySelectorAll('.ks-tab-link');

    // Activate the first tab and its content pane
    if (tabs.length > 0) {
      tabs[0].classList.add('active');
      const firstPane = document.getElementById(
        tabs[0].getAttribute('data-target')
      );
      firstPane.classList.add('active');
    }

    tabs.forEach((tab) => {
      tab.addEventListener('click', function () {
        const currentPane = document.querySelector('.ks-tab-pane.active');
        const targetPane = document.getElementById(
          tab.getAttribute('data-target')
        );

        $(tab).trigger('tab-change');
        currentPane.classList.remove('active');
        targetPane.classList.add('active');

        // Update active tab class
        tabs.forEach((t) => t.classList.remove('active'));
        tab.classList.add('active');
      });
    });
  });


});
