function closeAllTypeAheads() {
  $(".mdc-menu-surface").each(function () {
    $(this).removeClass('mdc-menu-surface--open')
  });
}

KPlusS = Object.assign({}, KPlusS || {},
  (function () {
    return {
      pagination: {
        onChangeSubmit,
        onSubmit
      },
      extendedSearch: {
        show: showExtendedSearch,
        hide: hideExtendedSearch,
        textField: {
          openTypeAhead,
          selectTypeAhead,
          clearInput,
          filterInput
        },
        date: {
          submitDate
        },
        select: {
          open,
          selectItem
        }
      },
      orderEntryItem: {
        showDetails: showOrderEntryItemDetails
      }
    }

    //region - pagination
    function onChangeSubmit(e) {
      const $form = $(this).closest('form')
      if (!$form.hasClass('submitting')) {
        $form.submit()
        console.log('onChangeSubmit', $form)
      }
    }

    function onSubmit(e) {
      const $form = $(this)
      console.log('onSubmit', $form)
      $form.addClass('submitting')
    }

    //endregion

    //region - extendedSearch
    function showExtendedSearch(e) {
      $('.account-section-content').addClass('showAdvancedSearch')
      $('#isAdvancedSearch').val(true)
    }

    function hideExtendedSearch(e) {
      const $advancedSearchForm = $('.js-advanced-search-form')
      $('#isAdvancedSearch').val(false)

      $("form.js-advanced-search-form :input").each(function () {
        let input = $(this);
        input.val('')
      })
      $advancedSearchForm.submit();
      $('.account-section-content').removeClass('showAdvancedSearch')
    }

    //region -- textField
    function openTypeAhead() {
      closeAllTypeAheads();

      const inputId = $(this).find('input').attr('id')
      const selectId = '#' + inputId + '-select'

      const wrapper = $(selectId)
      const select = wrapper.find($('.mdc-select'))
      const optionList = wrapper.find($('.mdc-menu-surface'))
      triggerSelectBox(select, optionList);
    }

    function selectTypeAhead() {
      const $option = $(this)
      const textInput = $option.closest('.account-order-history-advanced-search-wrapper').find('.mdc-text-field__input')
      const value = $option.data('value')
      const queryUrl = $option.data('url')
      const hiddenInput = $('#advancedSearchQuery')
      const $form = $('.js-advanced-search-form')

      textInput.text(value)
      hiddenInput.val(queryUrl)

      const select = $option.closest($('.mdc-select'))
      const optionList = $option.closest($('.mdc-menu-surface'))
      triggerSelectBox(select, optionList);
      $form.submit();
    }

    function clearInput() {
      const deleteButton = $(this)
      const hiddenQueryInput = $('#advancedSearchQuery')

      hiddenQueryInput.val(deleteButton.data('clear-query'))

      const $form = $('.js-advanced-search-form')
      $form.submit()
    }

    function triggerSelectBox(select, optionList) {
      select.addClass('mdc-select--activated')
      if (optionList.hasClass('mdc-menu-surface--open')) {
        optionList.removeClass('mdc-menu-surface--open')
      } else {
        optionList.addClass('mdc-menu-surface--open')
      }
    }

    function filterInput() {
      const $input = $(this)
      const options = $input.closest('.account-order-history-advanced-search-wrapper').find($('.mdc-select-wrapper')).find($('.mdc-list'))

      if ($input.val().length > 0) {
        const clearButton = $(this).closest('.mdc-text-field-wrapper').find('.mdc-text-field__clear')
        clearButton.css('display', 'block')
      } else {
        const clearButton = $(this).closest('.mdc-text-field-wrapper').find('.mdc-text-field__clear')
        clearButton.css('display', 'none')
      }

      options.find(".mdc-list-item").each(function () {
        var $option = $(this);
        var matchingText = $option.text();
        var re = new RegExp($input.val(), "i");

        var hasMatchedTerm = re.test(matchingText);

        if (hasMatchedTerm) {
          $option.show();
        } else {
          $option.hide();
        }
      });
    }

    //endregion

    //region -- date
    function submitDate() {
      const $form = $('.js-advanced-search-form')
      $form.submit();
    }

    //endregion

    //region -- select
    function open() {
      closeAllTypeAheads();
      const wrapper = $(this)
      var select = wrapper.find($('.mdc-select'))
      var optionList = wrapper.find($('.mdc-menu-surface'))
      triggerSelectBox(select, optionList)
    }

    function selectItem(e) {
      var $option = $(this)
      $('.mdc-select__selected-text').text($option.text())
      $('#hiddenSelectInput').val($option.data('value'))
      const select = $(this).closest($('.mdc-select'))
      const optionList = $(this).closest($('.mdc-menu-surface'))

      triggerSelectBox(select, optionList)
      e.preventDefault();
      e.stopPropagation();
    }

    //endregion
    //endregion

    //region - orderEntryItem
    function showOrderEntryItemDetails() {
      const showDetailListButton = $(this)
      const entryItemDetailList = showDetailListButton.closest($('.order-entry-item')).find($('.order-entry-item-detail-list'))

      const glyphicon = showDetailListButton.find($('.glyphicon'))

      if (entryItemDetailList.is(":hidden")) {
        entryItemDetailList.slideDown();
        glyphicon.removeClass('glyphicon-menu-down')
        glyphicon.addClass('glyphicon-menu-up')
      } else {
        entryItemDetailList.slideUp();
        glyphicon.removeClass('glyphicon-menu-up')
        glyphicon.addClass('glyphicon-menu-down')
      }
    }

    //endregion
  })()
)
