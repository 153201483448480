let contingentChart = null

function createChart(chartElement, dataSet, yAxesUnit) {
  const config = {
    type: 'bar',
    data: dataSet,
    options: {
      tooltips: {
        displayColors: true,
        callbacks: {
          title: (item, data) => {
            return data.datasets[item[0].datasetIndex].label
          },
          label: (item, data) => {
            return `${item.yLabel} ${data.datasets[item.datasetIndex].unit}`
          }
        }
      },
      scales: {
        x: {
          stacked: true,
          gridLines: {
            display: false
          },
          ticks: {
            fontSize: 18
          }
        },
        y: {
          stacked: true,
          ticks: {
            beginAtZero: true,
            callback: value => {
              return value ? value + ' ' + yAxesUnit : ''
            },
            fontSize: 18
          },
          type: 'linear'
        }
      },
      maintainAspectRatio: false
    }
  }
  if (contingentChart) {
    contingentChart.destroy();
  }
  contingentChart = new Chart(chartElement, config);
}

function openChartByList(soldToParty, month, baseProduct) {
  $('.account-contingent-check select[name=soldToParty]').val(soldToParty);
  $('.account-contingent-check select[name=date]').val(month);
  $('.account-contingent-check select[name=baseProduct]').val(baseProduct);
  KPlusS.chart.loadChart(soldToParty, month, baseProduct)
}

var KPlusS = Object.assign({}, Generic || {},
  (function () {
    return {
      chart: {
        selectOption,
        loadChart,
        openChartByList,
        hideErrorMessage
      },
      overviewList: {
        showOverviewList
      }
    }

    //region - chart
    function selectOption() {
      let allSelectsHasValue = true;
      $(".account-contingent-check select").each(function () {
        const value = $(this).find('option').filter(':selected').val();
        if (!value) {
          allSelectsHasValue = false;
        }
      });
      if (allSelectsHasValue) {
        const selectedSoldToParty = $('.account-contingent-check select[name=soldToParty]').val();
        const selectedDate = $('.account-contingent-check select[name=date]').val();
        const selectedBaseProduct = $('.account-contingent-check select[name=baseProduct]').val();
        loadChart(selectedSoldToParty, selectedDate, selectedBaseProduct)
        hideErrorMessage($('.account-contingent-check__error-message-invalid-input'));
      } else {
        showErrorMessage($('.account-contingent-check__error-message-invalid-input'));
      }
    }

    function loadChart(soldToParty, date, baseProduct) {
      hideOverviewList();
      const chartElement = $('#contingent-check');

      const allContingents = chartElement.data('all-contingents');
      const data = allContingents
        ?.productContingentsPerDatesBySoldToPartId[soldToParty]
        ?.productContingentsPerDate[date]
        ?.productContingents[baseProduct];
      if (!data) {
        showErrorMessage($('.account-contingent-check__error-message-no-data'));
      } else {
        const labels = [
          chartElement.data('i18n-first-bar') + ' ' + date,
          chartElement.data('i18n-second-bar') + ' ' + date,
          chartElement.data('i18n-third-bar')
        ];
        const chartData = populateChartData(data, chartElement, labels);
        hideErrorMessage($('.account-contingent-check__error-message-no-data'));
        hideErrorMessage($('.account-contingent-check__error-message-invalid-input'));
        createChart(chartElement, chartData, getUnit(data));
      }
    }

    function getUnit(data) {
      return data?.plannedQuantity?.unit?.code
        || data?.standardOpenQuantity?.unit?.code
        || data?.pickupQuantity?.unit?.code
        || data?.contractReleasedQuantity?.unit?.code
        || data?.contractPreviousReleasedQuantity?.unit?.code
        || data?.contractOpenQuantity?.unit?.code
        || data?.contractPreviousOpenQuantity?.unit?.code
    }

    function populateChartData(data, chartElement, labels) {
      return {
        labels: labels,
        datasets: [{
          label: chartElement.data('i18n-planned-quantity'),
          backgroundColor: 'rgb(102, 102, 102)',
          data: [data?.plannedQuantity?.quantity, 0, 0],
          unit: data?.plannedQuantity?.unit.code
        }, {
          label: chartElement.data('i18n-standard-open-quantity'),
          backgroundColor: 'rgb(0, 161, 58)',
          data: [0, data?.standardOpenQuantity?.quantity, 0],
          unit: data?.standardOpenQuantity?.unit.code
        }, {
          label: chartElement.data('i18n-pickup-quantity'),
          backgroundColor: 'rgb(252, 194, 67)',
          data: [0, data?.pickupQuantity?.quantity, 0],
          unit: data?.pickupQuantity?.unit.code
        }, {
          label: chartElement.data('i18n-contract-released-quantity'),
          backgroundColor: 'rgb(0, 58, 120)',
          data: [0, data?.contractReleasedQuantity?.quantity, data?.contractPreviousReleasedQuantity?.quantity],
          unit: data?.contractReleasedQuantity?.unit.code
        }, {
          label: chartElement.data('i18n-contract-open-quantity'),
          backgroundColor: 'rgb(159, 210, 243)',
          data: [0, data?.contractOpenQuantity?.quantity, data?.contractPreviousOpenQuantity?.quantity],
          unit: data?.contractOpenQuantity?.unit.code
        }]
      };
    }

    //endregion

    //region - overviewList
    function showOverviewList() {
      $('.account-contingent__overview').css('display', 'block')
      $('.contingent-check__wrapper').css('display', 'none')
    }

    function hideOverviewList() {
      $('.account-contingent__overview').css('display', 'none')
      $('.contingent-check__wrapper').css('display', 'block')
    }

    //endregion

    //region - misc
    function hideErrorMessage(element) {
      element.css('display', 'none');
    }

    function showErrorMessage(element) {
      element.css('display', 'block');
    }

    //endregion

  })()
)
