// Close select dropdown if user clicks outside -> maybe unbind mouseup if focus is lost
$(document).mouseup(function (e) {
  const container = $(".js-toggle-typeahead-handle-helper");
  if (!container.is(e.target) && container.has(e.target).length === 0) {
    closeAllTypeAheads();
  }
});

function closeAllTypeAheads() {
  $(".mdc-menu-surface").each(function () {
    $(this).removeClass('mdc-menu-surface--open');
  });
}

KPlusS = Object.assign({}, KPlusS || {},
  (function () {
    let $dateInput = null;
    let $dateInputWrapper = null;

    return {
      form: {
        typeahead: {
          openTypeAhead,
          selectOption,
          resetTypeAhead,
          filterTypeAhead
        },
        date: {
          openDatePicker,
          handleInput
        }
      },
    };

    //region - form
    //region -- typeahead
    function filterTypeAhead() {
      const $input = $(this);
      const options = $input.parents().eq(2).find($('.mdc-select-wrapper')).find($('.mdc-list'));

      // Overwriting globals
      const $resetButton = $input.closest($('div[data-on-click-call]')).find($('span[data-on-click-call]'));
      const $postFixedIcon = $input.closest($('div[data-on-click-call]')).find($('div.mdc-text-field__post-fixed-icon'));

      // Showing / hiding the clear icon and post fixed icon
      if ($input.val().length > 0) {
        toggleResetButton($resetButton, true);
        togglePostFixedIcon($postFixedIcon, false);
      } else {
        toggleResetButton($resetButton, false);
        togglePostFixedIcon($postFixedIcon, true);
      }

      // Filtering the options
      options.find(".mdc-list-item").each(function () {
        var $option = $(this);
        var matchingText = $option.text();
        var re = new RegExp($input.val(), "i");

        var hasMatchedTerm = re.test(matchingText);

        if (hasMatchedTerm) {
          $option.show();
        } else {
          $option.hide();
        }
      });
    }

    function resetTypeAhead() {
      // Clearing the input
      const $input = $(this).parent().find($('input.mdc-text-field__input'));
      $input.val('');
      $input.data('code', '');
      $input.trigger('typeahead-selected');

      // Resetting the drop down list
      const $options = $input.closest('.mdc-text-field-wrapper').parent().find('.mdc-select-wrapper').find('.mdc-list');
      $options.find(".mdc-list-item").each(function () {
        $(this).show();
      });

      // Icons
      const $resetButton = $input.closest('.mdc-text-field-wrapper').find($('.mdc-text-field__clear'));
      const $postFixedIcon = $input.closest('.mdc-text-field-wrapper').find($('.mdc-text-field__post-fixed-icon'));

      // Hide reset button
      toggleResetButton($resetButton, false);

      // Show post fixed icon
      togglePostFixedIcon($postFixedIcon, true);
    }

    function openTypeAhead() {
      closeAllTypeAheads();

      // JSP ATOMS
      const inputJspTagId = $(this).find('input').attr('id');
      const selectJspTagId = '#' + inputJspTagId + '-select';
      const wrapper = $(selectJspTagId);

      // Elements inside ATOMS
      const selectTag = wrapper.find($('.mdc-select'));
      const optionList = wrapper.find($('.mdc-menu-surface')); // Contains options role="listbox"

      toggleSelectBox(selectTag, optionList);
    }

    function selectOption() {
      const $option = $(this); // one of the options of the select.tag jsp
      const value = $option.data('value');
      const $textInput = $option.closest($('.mdc-select-wrapper')) // go up to find the dropdown wrapper
        .siblings('div[data-on-click-call]') // get the sibling that has the data on click call attribute
        .find($('input[data-on-input-call]')); // find the actual input which is also used for the typeahead input
      const code = $option.data('code');

      $textInput.data('code', code);
      $textInput.val(value);
      $textInput.trigger('typeahead-selected');

      const select = $option.closest($('.mdc-select'));
      const optionList = $option.closest($('.mdc-menu-surface'));

      toggleSelectBox(select, optionList);

      const $resetButton = $(this).closest($('.mdc-select-wrapper')) // go up to find the dropdown wrapper
        .siblings('div[data-on-click-call="KPlusS.form.typeahead.openTypeAhead"]')
        .find($('[data-on-click-call="KPlusS.form.typeahead.resetTypeAhead"]'));
      const $postFixedIcon = $resetButton.closest($('.js-toggle-typeahead-handle-helper')).find($('.mdc-text-field__post-fixed-icon'));

      // Show clear button
      toggleResetButton($resetButton, true);

      // Hide post fixed icon
      togglePostFixedIcon($postFixedIcon, false);
    }

    function toggleResetButton(resetButton, show) {
      show
        ? $(resetButton).css('display', 'grid')
        : $(resetButton).css('display', 'none');
    }

    function togglePostFixedIcon(icon, show) {
      show
        ? $(icon).removeClass('mdc-text-field__post-fixed-icon--hidden')
        : $(icon).addClass('mdc-text-field__post-fixed-icon--hidden');
    }

    function toggleSelectBox(select, optionList) {
      select.addClass('mdc-select--activated');

      optionList.hasClass('mdc-menu-surface--open')
        ? optionList.removeClass('mdc-menu-surface--open')
        : optionList.addClass('mdc-menu-surface--open');
    }

    //endregion

    //region -- date
    function openDatePicker() {
      $dateInputWrapper = $(this);
      $dateInput = $dateInputWrapper.find($('input[type="date"]'));

      $dateInput.get(0).showPicker();
    }

    function handleInput() {
      const value = $dateInput.val();

      handleErrorState(value);
    }

    function handleErrorState(date) {
      if (!date || date === '') {
        $dateInputWrapper.addClass('mdc-text-field--error');
      } else {
        $dateInputWrapper.removeClass('mdc-text-field--error');
      }
    }

    //endregion
    //endregion
  })()
);
